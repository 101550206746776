<template>
    <div class="page">
        <div class="box_1 flex-row justify-center">
            <div class="content-container">
                <div class="box_1-title flex-row justify-center">
                    <span class="text_title">{{$t('home.t1')}}</span>
                </div>
                <div class="box_1-content row">
                    <div class="col-md-6 box_1-content-left">
                        <div class="flex-row">
                            <div class="block flex-col justify-center">
                                <div>
                                    <div class="label-group flex-row justify-center">
                                        <img
                                                class="img-group"
                                                referrerpolicy="no-referrer"
                                                src="../assets/homePage/img/cloudCompute.png"
                                        />
                                    </div>
                                    <div class="text-group">{{$t('home.icon1')}}</div>
                                </div>
                            </div>
                            <div class="block flex-col justify-center">
                                <div>
                                    <div class="label-group flex-row justify-center">
                                        <img
                                                class="img-group"
                                                referrerpolicy="no-referrer"
                                                src="../assets/homePage/img/ai.png"
                                        />
                                    </div>
                                    <div class="text-group">AI</div>
                                </div>
                            </div>
                            <div class="block flex-col justify-center">
                                <div>
                                    <div class="label-group flex-row justify-center">
                                        <img
                                                class="img-group"
                                                referrerpolicy="no-referrer"
                                                src="../assets/homePage/img/lot.png"
                                        />
                                    </div>
                                    <div class="text-group">IOT</div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-row">
                            <div class="block flex-col justify-center">
                                <div>
                                    <div class="label-group flex-row justify-center">
                                        <img
                                                class="img-group"
                                                referrerpolicy="no-referrer"
                                                src="../assets/homePage/img/video.png"
                                        />
                                    </div>
                                    <div class="text-group">{{$t('home.icon2')}}</div>
                                </div>
                            </div>
                            <div class="block flex-col justify-center">
                                <div>
                                    <div class="label-group flex-row justify-center">
                                        <img
                                                class="img-group"
                                                referrerpolicy="no-referrer"
                                                src="../assets/homePage/img/audio.png"
                                        />
                                    </div>
                                    <div class="text-group">{{$t('home.icon3')}}</div>
                                </div>
                            </div>
                            <div class="block flex-col justify-center">
                                <div>
                                    <div class="label-group flex-row justify-center">
                                        <img
                                                class="img-group"
                                                referrerpolicy="no-referrer"
                                                src="../assets/homePage/img/borderCompute.png"
                                        />
                                    </div>
                                    <div class="text-group">{{$t('home.icon4')}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-row">
                            <div class="block flex-col justify-center">
                                <div>
                                    <div class="label-group flex-row justify-center">
                                        <img
                                                class="img-group"
                                                referrerpolicy="no-referrer"
                                                src="../assets/homePage/img/web.png"
                                        />
                                    </div>
                                    <div class="text-group">WEB</div>
                                </div>
                            </div>
                            <div class="block flex-col justify-center">
                                <div>
                                    <div class="label-group flex-row justify-center">
                                        <img
                                                class="img-group"
                                                referrerpolicy="no-referrer"
                                                src="../assets/homePage/img/5g.png"
                                        />
                                    </div>
                                    <div class="text-group">5G</div>
                                </div>
                            </div>
                            <div class="block flex-col justify-center">
                                <div>
                                    <div class="label-group flex-row justify-center">
                                        <img
                                                class="img-group"
                                                referrerpolicy="no-referrer"
                                                src="../assets/homePage/img/safe.png"
                                        />
                                    </div>
                                    <div class="text-group">{{$t('home.icon5')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 box_1-content-right">
                        <div class="section">
                            <div class="section-item flex-row align-center">
                                <img
                                        class="section-img"
                                        referrerpolicy="no-referrer"
                                        src="../assets/homePage/img/newTech.png"
                                />
                                <span class="section-title">{{$t('home.s1t')}}</span>
                            </div>
                            <span class="section-text">{{$t('home.s1p')}}</span>
                        </div>
                        <div class="section">
                            <div class="section-item flex-row align-center">
                                <img
                                        class="section-img"
                                        referrerpolicy="no-referrer"
                                        src="../assets/homePage/img/platform.png"
                                />
                                <div class="section-title">{{$t('home.s2t')}}</div>
                            </div>
                            <span class="section-text">
                                {{$t('home.s2p')}}
                            </span>
                        </div>
                        <div class="section">
                            <div class="section-item flex-row align-center">
                                <img
                                        class="section-img"
                                        referrerpolicy="no-referrer"
                                        src="../assets/homePage/img/colorProgram.png"
                                />
                                <span class="section-title">{{$t('home.s3t')}}</span>
                            </div>
                            <span class="section-text">
                               {{$t('home.s3p')}}
                            </span>
                        </div>
                        <div class="section">
                            <div class="section-item flex-row align-center">
                                <img
                                        class="section-img"
                                        referrerpolicy="no-referrer"
                                        src="../assets/homePage/img/safe.png"
                                />
                                <span class="section-title"> {{$t('home.s4t')}}</span>
                            </div>
                            <span class="section-text">  {{$t('home.s4p')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box_2 flex-row justify-center">
            <div class="content-container flex-col align-center">
                <span class="text_title1">{{$t('home.b2t')}}</span>
                <img
                        class="solution-img"
                        referrerpolicy="no-referrer"
                        src="../assets/homePage/img/solution.png"
                />
                <span class="paragraph_1">
                   {{$t('home.b2p')}}
                </span>
            </div>
        </div>
        <div class="box_3 flex-row justify-center">
            <div class="content-container flex-col justify-center">
                <div class="row">
                    <div class="text_title2 col-md-12"> {{$t('home.b3t')}}</div>
                    <div class="text_1 col-md-12">
                        {{$t('home.b3p')}}
                    </div>
                    <div class="col-md-12 image-wrapper-uosCertificates flex-row justify-between">
                        <svg  @click="getUosCertificate(1)" width="50" class="circle-last" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path d="M48 256c0 114.87 93.13 208 208 208s208-93.13 208-208S370.87 48 256 48S48 141.13 48 256zm212.65-91.36a16 16 0 0 1 .09 22.63L208.42 240H342a16 16 0 0 1 0 32H208.42l52.32 52.73A16 16 0 1 1 238 347.27l-79.39-80a16 16 0 0 1 0-22.54l79.39-80a16 16 0 0 1 22.65-.09z" fill="currentColor"></path></svg>
                        <viewer v-if="uosCertificatesActive===1">
                            <img
                              v-for="(item, index) in uosCertificates1" :key="index"
                              class="image-uosCertificates"
                              referrerpolicy="no-referrer"
                              :src="item.img"
                            />
                        </viewer>
                        <viewer v-if="uosCertificatesActive===2">
                            <img
                              v-for="(item, index) in uosCertificates2" :key="index"
                              class="image-uosCertificates"
                              referrerpolicy="no-referrer"
                              :src="item.img"
                            />
                        </viewer>
                        <svg  @click="getUosCertificate(2)" width="50" class="circle-next" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path d="M464 256c0-114.87-93.13-208-208-208S48 141.13 48 256s93.13 208 208 208s208-93.13 208-208zm-224 80.09L303.58 272H154v-32h149.58L240 175.91l22.71-22.54L364.54 256L262.7 358.63z" fill="currentColor"></path></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="box_4 flex-row justify-center" style="display: none">
            <div class="content-container">
                <div class="row">
                    <div class="col-md-12 image-wrapper-certificate">
                        <viewer>
                            <img
                              v-for="(item, index) in certificates" :key="index"
                              class="image-certificate"
                              referrerpolicy="no-referrer"
                              :src="item.img"
                            />
                        </viewer>
                    </div>
                </div>
            </div>
        </div>
        <div class="box_5 flex-row justify-center">
            <div class="content-container flex-col align-center">
                <div class="text_title1">{{$t('home.b5t')}}</div>
                <div class="logo-container">
                <img
                        class="image-partner"
                        referrerpolicy="no-referrer"
                        src="../assets/homePage/img/partner.png"
                />
                    <img src="../assets/homePage/img/haagen.png" alt="" class="img-haagen">
                </div>

                <div class="flex-row align-center justify-between">
                    <img src="../assets/homePage/img/zhaoXin.png" alt=""  class="partner-logo-normal">
<!--                    <img src="../assets/homePage/img/haagen.png" alt="" class="partner-logo">-->
                    <img src="../assets/homePage/img/HYGON.jpeg" alt=""  class="partner-logo-normal">
                    <img src="../assets/homePage/img/kylin.jpeg" alt=""  class="partner-logo-normal">
                    <img src="../assets/homePage/img/uos.png" alt=""  class="partner-logo-uos">
                    <img src="../assets/homePage/img/aoto.jpeg" alt=""  class="partner-logo-aoto">
                    <img src="../assets/homePage/img/jingGSubway.png" alt=""  class="partner-logo-subway">
                </div>
            </div>

        </div>
        <div class="content-container box-culture">
            <div class="row">
                <div class=" col-md-5 box-culture-item flex-row align-center">
                    <div class="image-wrapper-culture">
                        <img
                                class="image-culture-icon"
                                referrerpolicy="no-referrer"
                                src="../assets/homePage/img/culture-icon1.png"
                        />
                    </div>
                    <div class="group_52 flex-col justify-between">
                        <div class="flex-col justify-between">
                            <span class="text_2">{{$t("home.companyVision")}}</span>
                            <span class="text_3">COMPANY&nbsp;VISION</span>
                        </div>
                        <span class="paragraph_2">{{$t("home.p1")}}
                    <br/>
                    {{$t("home.p2")}}
                </span>
                    </div>
                </div>
                <div class=" col-md-5 offset-md-2 box-culture-item flex-row align-center">
                    <div class="image-wrapper-culture">
                        <img
                                class="image-culture-icon"
                                referrerpolicy="no-referrer"
                                src="../assets/homePage/img/culture-icon2.png"
                        />
                    </div>
                    <div class="group_52 flex-col justify-between">
                        <div class="flex-col justify-between">
                            <span class="text_2">{{$t("home.companyMission")}}</span>
                            <span class="text_3">COMPANY&nbsp;MISSION</span>
                        </div>
                        <span class="paragraph_2">{{$t("home.p3")}}</span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>

    </div>
  <!--            <Footer/>-->
</template>

<script>

export default {
  name: "HomePage",
    data(){
      return{
          uosCertificatesActive: 1,
          certificates: [
            {
                img: require('../assets/homePage/img/softcerti1.jpg'),
            },
            {
                img: require('../assets/homePage/img/softcerti2.jpg'),
            },
            {
                img: require('../assets/homePage/img/softcerti3.jpg'),
            },
            {
                img: require('../assets/homePage/img/softcerti4.jpg'),
            },{
                img: require('../assets/homePage/img/softcerti5.jpg'),
            },{
                img: require('../assets/homePage/img/softcerti6.jpg'),
            },
        ],
        uosCertificates1: [
            {
                img: require('../assets/homePage/img/UOSRecognition.jpeg'),
            },
           {
               img: require('../assets/homePage/img/UOSAMDr7.jpeg'),
           },
           {
               img: require('../assets/homePage/img/UOSDesktop.jpeg'),
           },
          ],
          uosCertificates2: [
              {
                  img: require('../assets/homePage/img/UOSinteli7.jpeg'),
              },
              {
                  img: require('../assets/homePage/img/UOSServer.jpeg'),
              },
            {
                img: require('../assets/homePage/img/UOSServer.jpeg'),
            },

          ],
      }
    },
    methods:{
        getUosCertificate(number){
            this.uosCertificatesActive = number;
        }
    }
}
</script>

<style scoped lang="css" src="../assets/common/common.css"/>
<style scoped lang="css" src="../assets/homePage/index.css"/>
