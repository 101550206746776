<template>
    <div>
    <div class="hardware-tab flex-row justify-center">
        <div class="content-container-90">
            <div class="row">
            <div class="hardware-model col-md-2">
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent(content1,'a200')">
                    <div class="hardware-text" :class="{'hardware-model-active':content1=== 'a200'}">{{$t("productCenter.h1")}}</div>
                    <img
                      v-show="content1==='a200'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent(content1,'a300')">
                    <div class="hardware-text" :class="{'hardware-model-active':content1=== 'a300'}">{{$t("productCenter.h2")}}</div>
                    <img
                      v-show="content1==='a300'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent(content1,'x100')">
                    <div class="hardware-text" :class="{'hardware-model-active':content1=== 'x100'}">{{$t("productCenter.h3")}}</div>
                    <img
                      v-show="content1==='x100'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center"  @click="showContent(content1,'sense')">
                    <div class="hardware-text" :class="{'hardware-model-active':content1=== 'sense'}">{{$t("productCenter.h4")}}</div>
                    <img
                      v-show="content1==='sense'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
            </div>
            <div class="hardware-img-wrapper col-md-10 flex-row justify-center">
                <div v-if="content1==='a200'" class="hardware-content-container">
                    <div class="hardware-img-name" >A200</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/A200.png"
                    />
                </div>
                <div v-if="content1==='a300'" class="hardware-content-container">
                    <div class="hardware-img-name">A300</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/A300.png"
                    />
                </div>
                <div v-if="content1==='x100'" class="hardware-content-container">
                    <div class="hardware-img-name">X100</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/x100.png"
                    />
                </div>
                <div v-if="content1==='sense'" class="hardware-content-container">
                    <div class="hardware-img-name"></div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/sense.png"
                    />
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="hardware-tab flex-row justify-center">
        <div class="content-container-90">
            <div class="row">
            <div class="hardware-model col-md-2">
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent2(content2,'a200')">
                    <div class="hardware-text" :class="{'hardware-model-active':content2=== 'a200'}">{{$t("productCenter.h1")}}</div>
                    <img
                      v-show="content2==='a200'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent2(content2,'a300')">
                    <div class="hardware-text" :class="{'hardware-model-active':content2=== 'a300'}">{{$t("productCenter.h2")}}</div>
                    <img
                      v-show="content2==='a300'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent2(content2,'x100')">
                    <div class="hardware-text" :class="{'hardware-model-active':content2=== 'x100'}">{{$t("productCenter.h3")}}</div>
                    <img
                      v-show="content2==='x100'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center"  @click="showContent2(content2,'sense')">
                    <div class="hardware-text" :class="{'hardware-model-active':content2=== 'sense'}">{{$t("productCenter.h4")}}</div>
                    <img
                      v-show="content2==='sense'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
            </div>
            <div class="hardware-img-wrapper col-md-10 flex-row justify-center">
                <div v-if="content2==='a200'" class="hardware-content-container">
                    <div class="hardware-img-name" >A200</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/A200.png"
                    />
                </div>
                <div v-if="content2==='a300'" class="hardware-content-container">
                    <div class="hardware-img-name">A300</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/A300.png"
                    />
                </div>
                <div v-if="content2==='x100'" class="hardware-content-container">
                    <div class="hardware-img-name">X100</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/x100.png"
                    />
                </div>
                <div v-if="content2==='sense'" class="hardware-content-container">
                    <div class="hardware-img-name"></div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/sense.png"
                    />
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="hardware-tab flex-row justify-center">
        <div class="content-container-90">
            <div class="row">
            <div class="hardware-model col-md-2">
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent3(content3,'a200')">
                    <div class="hardware-text" :class="{'hardware-model-active':content3=== 'a200'}">{{$t("productCenter.h1")}}</div>
                    <img
                      v-show="content3==='a200'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent3(content3,'a300')">
                    <div class="hardware-text" :class="{'hardware-model-active':content3=== 'a300'}">{{$t("productCenter.h2")}}</div>
                    <img
                      v-show="content3==='a300'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent3(content3,'x100')">
                    <div class="hardware-text" :class="{'hardware-model-active':content3=== 'x100'}">{{$t("productCenter.h3")}}</div>
                    <img
                      v-show="content3==='x100'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center"  @click="showContent3(content3,'sense')">
                    <div class="hardware-text" :class="{'hardware-model-active':content3=== 'sense'}">{{$t("productCenter.h4")}}</div>
                    <img
                      v-show="content3==='sense'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
            </div>
            <div class="hardware-img-wrapper col-md-10 flex-row justify-center">
                <div v-if="content3==='a200'" class="hardware-content-container">
                    <div class="hardware-img-name" >A200</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/A200.png"
                    />
                </div>
                <div v-if="content3==='a300'" class="hardware-content-container">
                    <div class="hardware-img-name">A300</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/A300.png"
                    />
                </div>
                <div v-if="content3==='x100'" class="hardware-content-container">
                    <div class="hardware-img-name">X100</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/x100.png"
                    />
                </div>
                <div v-if="content3==='sense'" class="hardware-content-container">
                    <div class="hardware-img-name"></div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/sense.png"
                    />
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="hardware-tab flex-row justify-center">
        <div class="content-container-90">
            <div class="row">
            <div class="hardware-model col-md-2">
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent4(content4,'a200')">
                    <div class="hardware-text" :class="{'hardware-model-active':content4=== 'a200'}">{{$t("productCenter.h1")}}</div>
                    <img
                      v-show="content4==='a200'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent4(content4,'a300')">
                    <div class="hardware-text" :class="{'hardware-model-active':content4=== 'a300'}">{{$t("productCenter.h2")}}</div>
                    <img
                      v-show="content4==='a300'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center" @click="showContent4(content4,'x100')">
                    <div class="hardware-text" :class="{'hardware-model-active':content4=== 'x100'}">{{$t("productCenter.h3")}}</div>
                    <img
                      v-show="content4==='x100'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
                <div class="hardware-model-item flex-row justify-around align-center"  @click="showContent4(content4,'sense')">
                    <div class="hardware-text" :class="{'hardware-model-active':content4=== 'sense'}">{{$t("productCenter.h4")}}</div>
                    <img
                      v-show="content4==='sense'"
                      class="img-three"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/三角形@2x.png"
                    />
                </div>
            </div>
            <div class="hardware-img-wrapper col-md-10 flex-row justify-center">
                <div v-if="content4==='a200'" class="hardware-content-container">
                    <div class="hardware-img-name" >A200</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/A200.png"
                    />
                </div>
                <div v-if="content4==='a300'" class="hardware-content-container">
                    <div class="hardware-img-name">A300</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/A300.png"
                    />
                </div>
                <div v-if="content4==='x100'" class="hardware-content-container">
                    <div class="hardware-img-name">X100</div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/x100.png"
                    />
                </div>
                <div v-if="content4==='sense'" class="hardware-content-container">
                    <div class="hardware-img-name"></div>
                    <img
                      class="img-arch"
                      referrerpolicy="no-referrer"
                      src="../assets/productCenter/img/sense.png"
                    />
                </div>
            </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: "ProductCenterHardware",
    data(){
        return{
            content1:'a200',
            content2:'a300',
            content3:'x100',
            content4:'sense'
        }
    },
    methods:{
        showContent(contentName,type){
            this.content1 = type;
        },
        showContent2(contentName,type){
            this.content2 = type;
        },
        showContent3(contentName,type){
            this.content3 = type;
        },
        showContent4(contentName,type){
            this.content4 = type;
        }
    }
}

</script>

<style scoped lang="css" src="../assets/common/common.css"/>
<style scoped lang="css" src="../assets/productCenter/index.css"/>
