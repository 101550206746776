<template>
    <div class="soft-tab">
        <div class="soft-intro flex-row justify-center">
            <div class="content-container flex-col align-center">
                <span class="soft-intro-title">{{$t("productCenter.b1t")}}</span>
                <div class="soft-intro-content">
                    {{$t("productCenter.b1p")}}
                </div>
            </div>
        </div>
        <div class="area-box flex-row justify-center">
            <div class="content-container flex-row justify-between">
                <div class="areaList col-md-2" v-for="(item, index) in areaData" :key="index">
                    <div class="areaIcon flex-row justify-center">
                        <img referrerpolicy="no-referrer" :src="item.areaIcon"/>
                    </div>
                    <div class="areaText text-center" v-html="item.areaText"></div>
                </div>
            </div>
        </div>
        <div class="img-arch-wrapper flex-row justify-center">
            <div class="content-container">
                <img
                        class="img-arch"
                        referrerpolicy="no-referrer"
                        src="../assets/productCenter/img/techArch.png"
                />
            </div>
        </div>
        <div class="img-boundary-wrapper flex-row justify-center">
            <div class="content-container">
                <img
                        class="img-boundary"
                        referrerpolicy="no-referrer"
                        src="../assets/productCenter/img/boundary.png"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ProductCenterSoft",
    data() {
        return {
            areaData: [
                {
                    areaIcon: require('../assets/productCenter/img/areaIcon1.png'),
                    areaText:  this.$t('productCenter.it1')
                },
                {
                    areaIcon: require('../assets/productCenter/img/areaIcon2.png'),
                    areaText: this.$t('productCenter.it2')
                },
                {
                    areaIcon: require('../assets/productCenter/img/areaIcon3.png'),
                    areaText: this.$t('productCenter.it3')
                },
                {
                    areaIcon: require('../assets/productCenter/img/areaIcon4.png'),
                    areaText: this.$t('productCenter.it4')
                },
                {
                    areaIcon: require('../assets/productCenter/img/areaIcon5.png'),
                    areaText: this.$t('productCenter.it5')
                },
                {
                    areaIcon: require('../assets/productCenter/img/areaIcon6.png'),
                    areaText: this.$t('productCenter.it6')
                }
            ]
        }
    },
}
</script>

<style scoped lang="css" src="../assets/common/common.css"/>
<style scoped lang="css" src="../assets/productCenter/index.css"/>
