<template>
    <div class="page flex-row justify-center">
        <div class="content-container">
            <div class="download-title">
                {{ $t('helpCenter.t') }}
            </div>
            <div class="download-content">
                <div class="row">
                    <div class="selectButton col-md-3">
                        <div class="page-button" :class="{'buttonSelected':pageType==='soft'}"
                             @click="changePage('soft')">{{ $t('helpCenter.t1') }}
                        </div>
                        <div class="page-button" :class="{'buttonSelected':pageType==='app'}"
                             @click="changePage('app')">{{ $t('helpCenter.t2') }}
                        </div>
                        <div class="page-button" :class="{'buttonSelected':pageType==='manual'}"
                             @click="changePage('manual')">{{ $t('helpCenter.t3') }}
                        </div>
                    </div>
                    <div class="showContent flex-col col-md-9" v-if="pageType==='soft'">
                            <div class="platform flex-row justify-around">
                                <img
                                        @click="changePlatform('android')"
                                        class="platform-img"
                                        referrerpolicy="no-referrer"
                                        :src="platform==='android'?androidQrcode?require('../assets/helpCenter/img/androidQrcode.png'):
                                    require('../assets/helpCenter/img/isAndroid.png'):require('../assets/helpCenter/img/notAndroid.png')"
                                />


                                <img
                                        @click="changePlatform('windows')"
                                        class="platform-img"
                                        referrerpolicy="no-referrer"
                                        :src="platform==='windows'?require('../assets/helpCenter/img/isWindows.png'):require('../assets/helpCenter/img/notWindows.png')"
                                />


                                <img
                                        @click="changePlatform('linux')"
                                        class="platform-img"
                                        referrerpolicy="no-referrer"
                                        :src="platform==='linux'?require('../assets/helpCenter/img/isLinux.png'):require('../assets/helpCenter/img/notLinux.png')"
                                />

                        </div>
                        <div class="platform-download-link flex-row align-center" v-if="platform==='android'">
                            <div class="download-button flex-row justify-center align-center">
                                <img
                                        class="download-img"
                                        referrerpolicy="no-referrer"
                                        src="../assets/helpCenter/img/download.png"
                                />
                                <a :href="androidUrl"><i
                                        class="fa fa-android fa-fw"></i>{{ $t('helpCenter.download') }}</a>
                            </div>
                            <div class="version-text">v{{ androidPlayerVersion }}</div>
                        </div>
                        <div class="platform-download-link flex-row align-center" v-if="platform==='windows'">
                            <div class="download-button flex-row justify-center align-center">
                                <img
                                        class="download-img"
                                        referrerpolicy="no-referrer"
                                        src="../assets/helpCenter/img/download.png"
                                />
                                <a :href="windowsUrl"><i
                                        class="fa fa-android fa-fw"></i>{{ $t('helpCenter.download') }}</a>
                            </div>
                            <div class="version-text">v{{ windowsPlayerVersion }}</div>
                            <div class="version-mark">x86</div>
                        </div>
                        <div class="platform-download-link flex-row align-center" v-if="platform==='linux'">
                            <div class="download-button flex-row justify-center align-center">
                                <img
                                        class="download-img"
                                        referrerpolicy="no-referrer"
                                        src="../assets/helpCenter/img/download.png"
                                />
                                <a :href="linuxUrl"><i
                                        class="fa fa-android fa-fw"></i>{{ $t('helpCenter.download') }}</a>
                            </div>
                            <div class="version-text">v{{ linuxPlayerVersion }}</div>
                            <div class="version-mark">arm64</div>
                        </div>

                    </div>

                    <div class="showContent col-md-9 flex-row justify-around" v-if="pageType==='app'">
                        <div class="row">
                            <div class="iphone col-md-6">
                                <img
                                        class="img-iphone"
                                        referrerpolicy="no-referrer"
                                        src="../assets/helpCenter/img/download_iphone@2x.png"
                                />
                            </div>
                            <div class="col-md-6 qrcode flex-col justify-center align-center">
                                <span class="qrcode-text">{{ $t('helpCenter.t2t') }}</span>
                                <img
                                        class="img-iphone-qrcode"
                                        referrerpolicy="no-referrer"
                                        src="../assets/helpCenter/img/download_qrcode@2x.png"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="showContent col-md-9" v-if="pageType==='manual'">
                        <div class="doc-link-wrapper" style="margin-bottom: 10px">
                        <a class="doc-link"
                           href="https://sts-dios.oss-cn-hangzhou.aliyuncs.com/site/doc/DisplayIOS%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C-v7.0.pdf"
                           download="DisplayIOS用户使用手册.pdf">{{ $t('helpCenter.t3t1') }}
                        </a>
                        </div>
                        <div class="doc-link-wrapper">
                            <a class="doc-link"
                               href="https://sts-dios.oss-cn-hangzhou.aliyuncs.com/site/doc/DisplayIOS%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4%E9%A2%84%E6%8E%88%E6%9D%83%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88v2.0).pdf"
                               download="DisplayIOS用户使用手册.pdf">{{ $t('helpCenter.t3t2') }}
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
  name: "HelpCenter",
  data() {
    return {
      platform: 'windows',
      pageType: 'soft',
      androidQrcode: false,
      androidPlayerVersion: '5.0.3',
      windowsPlayerVersion: '5.0.3',
      linuxPlayerVersion: '5.0.3',
      androidUrl: '',
      windowsUrl: '',
      linuxUrl: '',
    }
  },
  mounted() {
    this.getVersion();
  },
  methods: {
    changePage(type) {
      this.pageType = type;
    },
    getVersion() {
      fetch('/rest/sv/common/config/player/version') // 返回一个Promise对象
        .then((res) => {
          return res.text() // res.text()是一个Promise对象
        })
        .then((res) => {
          console.log(JSON.parse(res))// res是最终的结果
          this.androidPlayerVersion = JSON.parse(res).android && JSON.parse(res).android[0].version;
          this.windowsPlayerVersion = JSON.parse(res).win32 && JSON.parse(res).win32[0].version;
          this.linuxPlayerVersion = JSON.parse(res).linux && JSON.parse(res).linux[0].version;
          this.androidUrl = JSON.parse(res).android && JSON.parse(res).android[0].url;
          this.windowsUrl = JSON.parse(res).win32 && JSON.parse(res).win32[0].url;
          this.linuxUrl = JSON.parse(res).linux && JSON.parse(res).linux[0].url;
        })
    },
    changePlatform(platform) {
      if (this.platform === 'android' && platform === 'android') {
        this.androidQrcode = !this.androidQrcode;
      } else {
        this.androidQrcode = false;
      }
      this.platform = platform;
    }
  }
}
</script>

<style scoped lang="css" src="../assets/common/common.css"/>
<style scoped lang="css" src="../assets/helpCenter/index.css"/>
