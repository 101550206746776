<template>
    <div class="page">
        <div class="productType flex-row justify-center">
            <span class="soft-title" :class="{'active':content=== 'soft'}"
                  @click="showContent('soft')">{{$t("productCenter.t1")}}</span>
            <span class="hardware-title" :class="{'active':content=== 'hardware'}"
                  @click="showContent('hardware')">{{$t("productCenter.t2")}}</span>
        </div>
        <ProductCenterSoft id="soft" href="#soft"></ProductCenterSoft>
        <ProductCenterHardware id="hardware" href="#hardware"></ProductCenterHardware>
    </div>
</template>

<script>
import ProductCenterSoft from "@/components/ProductCenterSoft.vue";
import ProductCenterHardware from "@/components/ProductCenterHardware.vue";

export default {
  name: "ProductCenter",
  components: {ProductCenterSoft,ProductCenterHardware},
    mounted() {
      const _id = this.$route.params.id
        console.log('wwww')
      if (_id) {
        const element = document.getElementById(_id);
        if (element) {
          element.scrollIntoView(true,{behavior: 'smooth', block: 'start'});
        }
      }
    },
  data() {
    return {
      content: 'soft',
    }
  },
  methods: {
    showContent(type) {
      this.content = type;
      document.getElementById(type).scrollIntoView(true,{behavior: 'smooth', block: 'start'});
    }
  }
}
</script>

<style scoped lang="css" src="../assets/common/common.css"/>
<style scoped lang="css" src="../assets/productCenter/index.css"/>
