<template>
    <div id="app">
        <WebHeader></WebHeader>
        <div class="background-logo">
            <img
              class="banner"
              referrerpolicy="no-referrer"
              src="./assets/common/banner.png"
            />
        </div>
        <router-view/>
        <div class="footer flex-row justify-center">
            <div class="content-container">
                <div class="footer-top">
                    <div class="row">
                        <div class="footer-left-wrapper col-md-6 col-md-offset-1">
                            <div class="flex-col">
                                <span class="footer-title">{{$t('footer.ContactInformation')}}</span>
                                <br>
                                <br>
                                <span>{{$t('footer.tel')}}</span>
                                <br/>
                                <span>{{$t('footer.email')}}</span>
                                <br/>
                                <span>{{$t('footer.address')}}</span>
                            </div>
                        </div>
                        <div class="footer-right-wrapper flex-row col-md-6">
                            <div class="offical-qrcode flex-col">
                                <span class="footer-title">{{$t('footer.officialCode')}}</span>
                                <br>
                                <br>
                                <img class="image-offical-qrcode" referrerpolicy="no-referrer"
                                     src="./assets/common/官方公众号.png"/>
                            </div>
                            <div class="app-qrcode flex-col">
                                <span class="footer-title">{{$t('footer.app')}}</span>
                                <br>
                                <br>
                                <img class="image-app-qrcode" referrerpolicy="no-referrer"
                                     src="./assets/common/手机App.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom col-md-12">
            <span class="company">版权所有:&nbsp;北京思普时代技术有限公司&nbsp;©&nbsp;2010-{{nowYear}}</span>
            <img src="./assets/common/nation-logo.webp" alt="公安国徽" height="20px">
            <span style="padding-left: 3px">京公网安备11010202010800号</span>&nbsp;&nbsp;<span class="record-number"><a target="_blank" href="https://beian.miit.gov.cn/" style="color: white">京ICP备16043971号-3</a></span>&nbsp;&nbsp;
            <span>APP备案号:&nbsp;京ICP备16043971号-6A</span>
        </div>
        <live800-js-154777></live800-js-154777>
    </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

import WebHeader from "@/components/WebHeader.vue";

export default {
  name: 'App',
  data() {
      return {
          nowYear:new Date().getFullYear()
      }
  },
  components: {
    WebHeader,
    'live800-js-154777': {
      render(createElement) {
        return createElement('script', {
              attrs: {
                type: 'text/javascript',
                src: "https://chat8.live800.com/live800/chatClient/monitor.js?jid=1581051963&companyID=1622621&configID=154777&codeType=custom&ss=1&delayload=1",
              },
            },
        );
      },
    }
    // HelloWorld
  }
}
</script>
<style>
body {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
<style scoped lang="css" src="./assets/common/common.css"/>
