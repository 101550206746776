import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import VueRouter from 'vue-router'
import router from './router/index'
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
Vue.use(BootstrapVue)
Vue.use(Viewer);
Viewer.setDefaults({
  Options: { "inline": false, "button": false, "navbar": false, "title": true, "toolbar": false, "tooltip": true, "movable": true, "zoomable": true, "rotatable": false, "scalable": false, "transition": true, "fullscreen": true, "keyboard": false, "url": "data-source" }
});
const i18n = new VueI18n({
  // locale: "zh", // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  locale: localStorage.getItem('lang') || 'zh',
  messages: {
    zh: require("./vueI18n/language-zh"),
    en: require("./vueI18n/language-en"),
  },
});
Vue.config.productionTip = false
Vue.use(VueRouter);

new Vue({
  render: h => h(App),
  router,
  i18n,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
