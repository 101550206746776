import VueRouter from 'vue-router'
import HomePage from "@/components/HomePage.vue";
import ProductCenter from "@/components/ProductCenter.vue";
import OurSolution from "@/components/OurSolution.vue";
import CooperationCase from "@/components/CooperationCase.vue";
import AboutUs from "@/components/AboutUs.vue";
import HelpCenter from "@/components/HelpCenter.vue";
const router = new VueRouter({
  routes:[
    {
      path:'/',
      component:HomePage,
      meta: { title: '【官网】迪捷思-新一代数字媒体智能播控系统' }
    }, {
      path:'/HomePage',
      redirect:'/',
      component:HomePage,
      meta: { title: '【官网】迪捷思-新一代数字媒体智能播控系统' }
    }, {
      path:'/ProductCenter/:id?',
      component:ProductCenter,
      meta: { title: '【官网】迪捷思-产品中心' }
    }, {
      path:'/OurSolution',
      component:OurSolution,
      meta: { title: '【官网】迪捷思-解决方案' }
    }, {
      path:'/CooperationCase/:id?',
      component:CooperationCase,
      meta: { title: '【官网】迪捷思-合作案例' }
    }, {
      path:'/AboutUs',
      component:AboutUs,
      meta: { title: '关于我们' }
    },{
      path:'/HelpCenter',
      component:HelpCenter,
      meta: { title: '帮助中心' }
    },{
      path: '*', // 其他没有的页面都重定向到 home页面去
      redirect: '/HomePage'
    }
  ]
})
//路由前置守卫；用来设置元信息
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})
export default router
