<template>
    <div class="page">
        <div class="box_1 flex-row justify-center">
            <div class="content-container">
                <div class="box_1-title flex-row justify-center">
                    <span class="text_title">{{$t("aboutUs.aboutUs")}}</span>
                </div>
                <div class="paragraph_1">
                    {{$t("aboutUs.p1")}}
                    <br>
                    <div class="paragraph_2">
                        {{$t("aboutUs.p2")}}
                    </div>
                </div>
                <div class="flex-row justify-center">
                    <span class="text_title1">{{$t("aboutUs.contactUs")}}</span>
                </div>
                <img
                        class="connect-img"
                        referrerpolicy="no-referrer"
                        src="../assets/aboutUs/img/联系我们@2x.jpg"
                />

                <div class="connect-info row">
                    <div class="phone col-md-3">
                        <img
                                class="connect-info-img"
                                referrerpolicy="no-referrer"
                                src="../assets/aboutUs/img/电话icon@2x.png"
                        />
                        <span>{{$t("aboutUs.tel")}} </span>
                    </div>
                    <div class="email col-md-4">
                        <img
                                class="connect-info-img"
                                referrerpolicy="no-referrer"
                                src="../assets/aboutUs/img/邮箱icon@2x.png"
                        />
                        <span>{{$t("aboutUs.email")}}   </span>
                    </div>
                    <div class="address col-md-5">
                        <img
                                class="connect-info-img"
                                referrerpolicy="no-referrer"
                                src="../assets/aboutUs/img/地址icon@2x.png"
                        />
                        <span>{{$t("aboutUs.address")}} </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="box_2 flex-row justify-center">
            <div class="content-container">
                <div class="flex-row justify-center">
                    <span class="text_title1">{{$t('aboutUs.ourHonor')}}</span>
                </div>

                <div class="image-wrapper-certificate flex-row justify-between">
                    <div class="flex-row justify-end align-center" @click="getCertificate(1)">
                        <img
                                class="image-certificate-button"
                                referrerpolicy="no-referrer"
                                src="../assets/aboutUs/img/向左@2x.png"
                        />
                    </div>
                    <viewer v-if="certificatesActive===1" class="flex-row justify-between">
                        <img
                                v-for="(item, index) in certificates1" :key="index"
                                class="image-certificate"
                                referrerpolicy="no-referrer"
                                :src="item.img"
                        />
                    </viewer>
                    <viewer v-if="certificatesActive===2" class="flex-row justify-between">
                        <img
                                v-for="(item, index) in certificates2" :key="index"
                                class="image-certificate"
                                referrerpolicy="no-referrer"
                                :src="item.img"
                        />
                    </viewer>
                    <div class="flex-row align-center"  @click="getCertificate(2)">
                    <img
                            class="image-certificate-button"
                            referrerpolicy="no-referrer"
                            src="../assets/aboutUs/img/向右@2x.png"
                    /></div>
                </div>

            </div>
        </div>

        <div class="box_62 flex-row justify-center">
            <div class="content-container flex-col align-center">
                <div class="text_title1">{{$t('aboutUs.partner')}}</div>
                <img
                        class="image-partner"
                        referrerpolicy="no-referrer"
                        src="../assets/homePage/img/partner.png"
                />
            </div>
        </div>
        <div class="box_19 flex-row align-center">
            <div class="area-box flex-row justify-center">
                <div class="content-container flex-row justify-between">
                    <div class="areaList col-md-3" v-for="(item, index) in areaData" :key="index">
                        <div class="areaIcon flex-row justify-center">
                            <img referrerpolicy="no-referrer" :src="item.areaIcon"/>
                        </div>
                        <div class="areaText text-center" v-html="item.areaText"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
        certificatesActive:1,
      areaData: [
        {
          areaIcon: require('../assets/aboutUs/img/自主创新为根本@2x.png'),
          areaText: this.$t('aboutUs.t1')
        },
        {
          areaIcon: require('../assets/aboutUs/img/技术创新为目标@2x.png'),
          areaText: this.$t('aboutUs.t2')
        },
        {
          areaIcon: require('../assets/aboutUs/img/服务到位为保障@2x.png'),
          areaText: this.$t('aboutUs.t3')
        },
        {
          areaIcon: require('../assets/aboutUs/img/质量上乘为基础@2x.png'),
          areaText: this.$t('aboutUs.t4')
        },
      ],
      certificates1: [
        {
          img: require('../assets/homePage/img/softcerti1.jpg'),
          imgNumber: 1,
        },
        {
          img: require('../assets/homePage/img/softcerti2.jpg'),
          imgNumber: 2,
        },
        {
          img: require('../assets/homePage/img/softcerti3.jpg'),
          imgNumber: 3,
        },
        {
          img: require('../assets/homePage/img/softcerti4.jpg'),
          imgNumber: 4,
        },
      ],
      certificates2: [
        {
          img: require('../assets/homePage/img/softcerti3.jpg'),
          imgNumber: 3,
        },
        {
          img: require('../assets/homePage/img/softcerti4.jpg'),
          imgNumber: 4,
        },{
          img: require('../assets/homePage/img/softcerti5.jpg'),
          imgNumber: 5,
        },{
          img: require('../assets/homePage/img/softcerti6.jpg'),
          imgNumber: 6,
        },
      ],
    }
  },
    methods:{
        getCertificate(number){
            this.certificatesActive = number;
        }
    }
}
</script>

<style scoped lang="css" src="../assets/common/common.css"/>
<style scoped lang="css" src="../assets/aboutUs/index.css"/>
