module.exports = {
  aboutUs: {
    aboutUs: "About Us",
    t1: 'Independent innovation is fundamental',
    t2:'Technological innovation is the goal',
    t3:'Based on superior quality',
    t4:'Service is guaranteed',
    p1:'DisplayIOS is a sub-brand of Beijing Sipu Times Technology Co., Ltd., focusing on providing SaaS platform services and overall product solutions for intelligent commercial display systems. DisplayIOS relies on high technology to create a one-stop management and operation solution for digital screens, allowing screens to have functions such as intelligent management and control, information release, fun interaction, display and application; in order to provide better services. DisplayIOS also provides privatized deployment solutions to meet the customized development of personalized functions of digital screens, and provides screen generation operation services, with a professional team providing full personal service. ',
    p2: "The company's core team was jointly founded by Internet IT experts in 2010. It has now connected to offline electronic screens in 200 cities across the country, covering chain stores, transportation, catering and shopping, cultural tourism scenic spots, business offices, community residences, and smart hotels. , eight life scenarios of smart medical care. ",
    contactUs:'Contact us',
    partner:'Partner',
    ourHonor:'our honor',
    tel:'Tel: 400-690-8766 010-57108766',
    email:'Email: service@siptimes.com',
    address:'Address: Room 1705, North Ring Center, No. 18 Yumin Road, Xicheng District, Beijing'
  },
  webHeader:{
    home:'Home',
    productCenter:'Product',
    solution:'Solution',
    cooperationCase:'Case',
    aboutUs:'Us',
    helpCenter:'Help'
  },
  home:{
    companyVision :"Company Vision",
    companyMission:'Company Mission',
    p1:'Using digital technology to drive world progress',
    p2: 'Helping every organization achieve great things',
    p3:'Build a smart world connected by thousands of screens',
    t1:'DisplayIOS Advantages',
    icon1:'Cloud',
    icon2:'Video',
    icon3:'Audio',
    icon4:'Edge Computing',
    icon5:'Safe',
    s1t:'Advanced Technology',
    s1p:'DisplayIOS is a new generation of commercial display intelligent operating system based on advanced technologies such as cloud computing, edge computing, AI, and IoT.',
    s2t:'Create a basic software platform for the commercial display industry',
    s2p:'The product is committed to building a basic software platform for the commercial display industry, supporting the development of industry software, assisting industrial upgrading, and welcoming the era of cloud computing, AI, and 5G.',
    s3t:'Create amazing digital shows quickly',
    s3p:'DisplayIOS is designed with forward-looking, scalable and practical concepts, and adopts centralized control and unified management. It can quickly produce exciting digital programs (video, audio, WEB applications), publish them to the terminal\'s large screen, and interact with the audience. High quality communication and interaction.',
    s4t:'Powerful functions, simple operation, safe and stable',
    s4p: 'DisplayIOS is powerful, easy to operate, safe and stable, and can quickly establish high-quality media broadcast control intelligent applications for users while reducing system construction costs and complexity. ',
    b2t: 'Professional customized solutions',
    b2p: 'Our customers are located in all provinces and autonomous regions except Tibet, Hong Kong, Macau, and Taiwan. We have a highly skilled national support team and serve nearly 10,000 stores. Covering multiple industries including airports, restaurants, high-speed rail, medical care, government and enterprises, and digital media. It also provides customers with professional customized solutions. ',
    b3t: 'Independent research and development, localization is safe and reliable',
    b3p:"DisplayIOS Digital Media Intelligent Operating System, the platform code and terminal code are completely independently developed, and have passed a series of domestic mutual compatibility certifications such as those of Kirin and Tongxin, ensuring the platform's security needs in governments and enterprises. ",
    b5t:'Partner'
  },
  footer:{
    ContactInformation:'Contact information',
    tel:'Tel : 400-690-8766  010-57108766',
    email:'Email: service@siptimes.com',
    address:'Address:1705, North Central Center Building,the 18th of Yu Min Road, Xicheng District, Beijing',
    officialCode:'Official public account',
    app:'Mobile App'
  },
  productCenter: {
    t1:'Di Jie Si broadcast control software',
    t2:'Di Jie Si hardware equipment',
    b1t:'Di Jie Si broadcast control software',
    b1p:'DisplayIOS digital media intelligent broadcast control system is a new generation of digital media intelligent broadcast control system based on advanced technologies such as cloud computing, edge computing, IoT, AI, and 5G. It covers everything from digital program production to Through the centralized control and unified management of broadcast control content and equipment, high-quality communication and interaction with the audience can be perfectly realized through the various processes of arrangement, review, and release to large-screen playback. The system is powerful, simple to operate, safe and stable, supports cloud deployment and local deployment, and meets the needs of different users. The system is deeply integrated with businesses in different industries, and can be upgraded to build industry versions of commercial display intelligent application systems in specific subdivisions, such as smart wards and so on. ',
    it1:'brand chain',
    it2:'Airport Metro',
    it3:'Smart Ward',
    it4:'Government and enterprise digital media',
    it5:'Advertising Media',
    it6:'Xinchuang Products',
    h1:'Commercial model',
    h2:'Engineering model',
    h3:'Immersive model',
    h4:'Smart sensor'
  },
  solution: {
    p1: 'Committed to the development and application of edge computing basic platforms in the field of intelligent commercial display, and providing professional Internet digital media intelligent broadcast control solutions for airports, subways, restaurants, retail and other industries. ',
    t0:'AI digital robot system',
    t1:'Advertising Media',
    t2:'Brand chain',
    t3:'Government and enterprise digital media',
    t4:'Innovation plan',
    t0t:'DisplayIOS AI digital robot system',
    t1t:'Integrated commercial display solution for the advertising industry',
    t0p1:'DisplayIOS AI virtual digital human uses AI deep learning, big data, and Internet of Things technology. DisplayIOS AI virtual digital human presents a new way of human-computer interaction. As a substitute for the currently widely used self-service machines or with certain professional With the virtual machine digital robot of knowledge, customers can handle various businesses without contact by talking to the virtual digital person on the screen. The interaction is natural and powerful, which greatly improves the user experience and business processing efficiency. ',
    t0p2:'Widely used in hospitals, shopping malls, government halls, airport transportation hubs, hotels, business halls and other places. ',
    t0p3:'DisplayIOS AI virtual digital people have the following three characteristics: First, the appearance of the owner, with specific characteristics such as appearance, gender and personality; second, the behavior of the owner, with language, facial expressions and body movements. The ability to express movements; the third is to have human thoughts, the ability to recognize the external environment and communicate and interact with people. ',
    t0dt1:'System framework',
    t0dt1p1:'DisplayIOS AI virtual digital human system consists of 7 modules including character image, voice generation, animation generation, audio and video synthesis display, interaction, professional wisdom engine and artificial assistance. ',
    t0dt2:'Non-interactive virtual digital human system operation process',
    t0dt2p1:'DisplayIOS AI virtual digital human interaction module is an extension. Depending on its presence or absence, digital humans can be divided into interactive digital humans and non-interactive digital humans. ',
    t0dt2p2:'The operation process of the non-interactive digital human system is shown in the figure. The system generates corresponding character voices and animations based on the target text, and synthesizes audio and video to present to the user. ',
    t0dt3:'Intelligent-driven virtual digital human operation process',
    t0dt3p1:'Interactive digital humans can be divided into intelligent-driven and real-person driven types according to different driving methods. ',
    t0dt3p2: 'The intelligent-driven digital human can automatically read and analyze external input information through the intelligent system, decide the subsequent output text of the digital human based on the analysis results, and then drive the character model to generate corresponding voices and actions to enable the digital human to interact with the user. . This character model is pre-trained through AI technology and can be driven by text to generate speech and corresponding animation. This model is called the TTSA (Text To Speech & Animation) character model in the industry. ',
    t0dt4:'Real person-driven virtual digital human operation process',
    t0dt4p1: 'Interactive digital humans can be divided into intelligent-driven and real-person driven types according to different driving methods. ',
    t0dt4p2: 'Real-person-driven digital humans drive digital humans through real-person people. The main principle is that real-person people communicate with users in real-time according to user videos transmitted from the video surveillance system. At the same time, the real-person expressions and movements are presented on the screen through the motion capture collection system. Virtual digital human image to interact with the user. ',
    t0dt5:'Technical architecture',
    t0dt5p1: 'Virtual digital humans are divided into 2D and 3D digital humans. 3D digital humans require additional use of three-dimensional modeling technology to generate digital images. The information dimension increases and the amount of calculation required is greater. ',
    t0dt5p2: 'Each type of digital human includes five major technical modules for the production and interaction of digital virtual humans, namely character generation, character expression, synthetic display, recognition and perception, analysis and decision-making and other modules. Among them, character expression includes speech generation and animation generation. Animation generation consists of two parts: driver (action generation) and rendering. ',
    t1p1:'1. Accurately locate the target audience: Through big data analysis and artificial intelligence technology, programmatic buying can accurately locate the target audience and improve the accuracy and effectiveness of advertising. ',
    t1p2:'2. Automated delivery: Programmatic buying can automate the process of advertising delivery, reduce manual intervention, and improve the efficiency of advertising delivery. ',
    t1p3:'3. Real-time monitoring and optimization: Programmatic buying can monitor the effectiveness of advertising in real time and optimize based on data to improve advertising ROI. ',
    t1p4:'4. Cost savings: Programmatic buying can reduce labor and time costs, improve advertising efficiency, and reduce advertising costs. ',
    t2t:'Brand chain store enterprise commercial display application solution',
    t2p1: 'At a time when offline stores need to transform, the younger generation likes technology-rich experiences. How the retail industry can capture customers and improve customer stickiness is the first thing to consider. Stores use digital screens to build smart stores, allowing customers to enjoy good products and experience the convenient services brought by digitalization. ',
    t3t:'Intelligent building multimedia broadcast control system solution',
    t3p1: 'The digital transformation of digital government affairs and enterprises is in full swing. Various governments and enterprises make full use of various large screens distributed in many spaces in smart buildings, and use the DisplayIOS digital media intelligent broadcast control system to carry out relevant comprehensive information Release monitoring, publicity display and interactive contact, create government affairs exhibition halls, corporate exhibition halls, party building publicity rooms, etc. in the digital era, and build efficient, automatic, humanized modern government affairs and office intelligent buildings full of technology. ',
    t4t:'Information technology application innovation (Xinchuang) solution',
    t4p1: 'Autonomous control has become a necessary condition for the digital transformation of key fields such as government, finance, transportation, and energy. DisplayIOS is based on a variety of domestic information and communication technology architecture platforms, and is compatible with a variety of domestic operating systems (Kylin OS, Tongxin UOS) and domestic servers and terminal hardware (Haiguang, Zhaoxin). It has created a domestic commercial display intelligent broadcast control system, filling the gap in commercial display broadcast control information and communication products.',
  },
  cooperationCase:{
    t1:'Haagen-Dazs: transformation and upgrading of traditional stores',
    t2: 'Taiyuan Metro: Create a green, humanistic and smart subway',
    t3:'Ordos Airport: Digital media transformation and upgrading of the new airport',
    t4:'Waldorf Astoria: A new immersive hotel experience',
    t5:'Meizhou Dongpo: Digital catering store upgrade',
    t6:'Wrigley Bathroom: A new paradigm for domestic brand smart stores',
    t7:'Gubei Water Town Scenic Spot: Digital Transformation of Internet Celebrity Scenic Spot',
    t1t1:'Project background',
    t1t2:'Project pain points',
    t1t3:'Implementation plan',
    t1p1: 'Break the traditional large-screen U disk playback in stores and transform and upgrade to digital stores',
    t1p2: '1. It is difficult to update U disk playback and has poor timeliness; 2. It cannot be managed uniformly; 3. The maintenance cost is high; 4. The transmission of marketing activity information is slow. ',
    t1p3: 'The openness of the DisplayIOS platform is compatible with third-party hardware platforms, enabling unified platform operations. It not only enhances the influence of the Haagen-Dazs brand, but also saves manpower and material resources in stores. At the same time, marketing activities are spread across the country in a timely manner. Each store has improved communication efficiency. ',
    t1p4:'DisplayIOS/Unified broadcast control/Third-party hardware compatibility/Background music',
    t2t1:'Project background',
    t2t2:'Project requirements',
    t2t3:'Implementation plan',
    t2t4:'Function analysis',
    t2p1: 'Taiyuan Metro Line 2 aims to create a "green subway, humanistic subway, and smart subway". Nearly 2,000 multimedia equipment displays are laid across the entire line. It is hoped that through the expression of digital multimedia full scenes, it can break the limitations of physical space and realize domestic The first subway with culture-led and integrated operation of "all media and all scenes"; the first subway in the country with an editing and broadcasting system; the first subway in the world with a story system. ',
    t2p2:'1. A platform that can manage all the screens of Taiyuan Metro Line 2; 2. The platform is stable and can support multiple display hardware at the same time; 3. The platform has strong compatibility and can support multiple system devices;',
    t2p3: 'By using the DisplayIOS commercial display operating system, we implemented localized security deployment for the Taiyuan Metro, implemented a comprehensive management system for large screens, and implemented broadcast control for all large screens in 23 stations across the line to implement multiple display terminals and multiple systems. compatibility, achieving platform stability while utilizing various functions of the platform to achieve "all-media, all-scenario" integrated operations. ',
    t2p4: 'Local deployment (private cloud service)/third-party system docking/third-party hardware compatibility/unified management across the board',
    t2p5: 'It is compatible with a variety of display terminals at the same time, including LED screens, LCD screens, transparent screens, slide screens, and projectors; it is also compatible with multi-system equipment, Windows, Android and Linux systems; it can uniformly manage the entire line, entrance and exit elevator media, Guide screens, in-station elevator media, corner screens, and main point creative screens. ',
    t3t1:'Project background',
    t3t2:'Project requirements',
    t3t3:'Implementation plan',
    t3t4:'Scene analysis',
    t3t5:'Synchronized playback',
    t3t6:'Live streaming service',
    t3t7:'Unified management',
    t3p1: 'The electronicization and Internetization of airport advertising media is an irresistible trend. At present, the total amount of electronic media in some airports has reached 50%, and some airports have even reached 70%. Ordos Airport hopes that in terms of information volume and communication influence, digital media and traditional advertising media will complement each other and form a synergy to better meet the urgent needs of advertisers, give full play to the combined advantages, and build an online and offline dual platform, which will not only strengthen The experience and interactivity of offline media can effectively cooperate with online media in terms of release time, frequency, and content, each showing its advantages. ',
    t3p2: '1. Large screens, advertising machines, and systems are not unified, and unified management cannot be achieved; 2. The original platform can only meet basic display needs, and Ordos Airport hopes to be scalable without the need for engineering development. ',
    t3p3:'DisplayIOS local deployment (private cloud service)/third-party hardware compatibility/synchronous playback/live broadcast service',
    t3p4: 'Arrival level: vertical advertising machine Departure level: three-sided screen, vertical double-sided screen, vertical advertising machine Hall: vertical advertising machine',
    t3p5:"Synchronized playback in different areas brings a visual impact to tourists and attracts advertisers' willingness to place ads. ",
    t3p6: 'Live broadcast service for major events: unified live broadcast for major festivals (Spring Festival, National Day, etc.), live broadcast service for major receptions. ',
    t3p7: 'It has solved the various controls of the original different systems. Now all different types of screen terminals in the airport are managed uniformly through the DisplayiOS system, which not only improves work efficiency, but also improves the playback effect. ',
    t4t1:'Project background',
    t4t2:'Project requirements',
    t4t3:'Implementation plan',
    t4p1: 'As a top hotel luxury brand, Waldorf Astoria hopes to create a mysterious and fantasy starry sky to bring guests the ultimate experience, so the romantic Waldorf Astoria Dreamy Starry Sky Afternoon Tea was born. Let technology help, and with the help of DisplayIOS digital media intelligent broadcast control system, the mysterious space will be presented to guests at close range. A projection lamp is set up above each table. The projection lamp is equipped with a temperature sensor, which can detect the different temperatures of the drinks on the table, and make diversified interactive responses based on the temperatures of different drinks and food on the table, creating colorful and mysterious nebulae and stars all over the sky. Floating on the table, it creates a romantic, colorful, mysterious and dreamy starry sky atmosphere for guests, making people feel like they are in space. ',
    t4p2:'1. Temperature sensing interaction 2. Remote broadcast control unified management 3. Mobile app remote control',
    t4p3: 'Through the openness of the DisplayiOS commercial display operating system, it can be integrated and compatible with third-party hardware, and after customized development, the temperature sensing effect can be achieved. ',
    t4p4:'DisplayIOS /LWA application/Third-party hardware integration/Third-party hardware compatibility/Remote management/Customized development and design/Mobile APP broadcast control',
    t5t1:'Project background',
    t5t2:'Project pain points',
    t5t3:'Implementation plan',
    t5p1: "As a new smart catering company that sponsors the Beijing Winter Olympics, Meizhou Dongpo's digital store upgrade is imminent. ",
    t5p2: '1. Traditional U disk playback; 2. Unable to be managed uniformly; 3. Synchronous playback effect cannot be achieved, and the joint screen playback in stores is chaotic; 4. As a sponsor of the Beijing Winter Olympics. ',
    t5p3:'DisplayIOS/Unified broadcast control/Third-party hardware compatibility/TV live broadcast',
    t6t1:'Project background',
    t6t2:'Project pain points',
    t6t3:'Implementation plan',
    t6t4:'Scenario analysis',
    t6p1: "As a long-established domestic bathroom brand, Wrigley Bathroom hopes to promote the company's marketing activities in new ways and improve the effectiveness. As the 'first to eat crabs' in the new bathroom retail strategy, the construction of digital stores from 0 to 1 is imminent. . ",
    t6p2:'1. Offline and online channels were originally two completely separate channels, and they need to be connected from scratch; 2. Offline physical stores are increasingly losing their appeal, and a strong traffic diversion strategy is needed. ',
    t6p3:'DisplayIOS/Third-party data docking/Third-party hardware compatibility/scene app',
    t6p4: 'In-store-LED screen, Douyin live event, theme game customization, big turntable',
    t7t1:'Project Overview',
    t7t2:'Program strategy',
    t7t3:'Function analysis',
    t7t4:'Implementation plan',
    t7p1:"Gubei Water Town, as one of Beijing's excellent resorts, provides every tourist with a family-friendly experience. With the development of digital media, Gubei Water Town is committed to creating the best tourist experience environment and gradually carrying out digital media transformation in the scenic area. ",
    t7p2:'Use DisplayIOS commercial display operating system and hardware equipment to achieve localized and safe deployment. Carry out unified broadcast control and management for all large screens, and at the same time ensure the simultaneous playback effect of all large screens. ',
    t7p3:'The large screen is located in the lobby area of the tourist service center, where it radiates the widest number of people. Through the Digis smart screen control system, brand activity information can be displayed in various ways such as synchronized playback, carousel, insertion, and scheduled playback to visually attract tourists. ',
    t7p4:"The tourist service center builds a large publicity screen to achieve the effect of transmitting information to tourists. Unified management of large screens. Screen sync playback. Beizhaihua's security deployment is directly managed by the event control center. ",
  },
  helpCenter:{
    t:'Download Center',
    t1:'Playback software download',
    t2:'App download',
    t3:'User Manual',
    download: 'Download',
    t2t: 'Scan the QR code below to download',
    t3t1: 'DisplayIOS User Manual (v7.0)',
    t3t2: 'DisplayIOS partner pre-authorization operation (v2.0)',
  }
};
