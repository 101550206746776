<template>
  <div class="page">
    <div class="case-content flex-row justify-center">
      <div class="content-container">
        <div id="section1" href="#section1">
        <div class="case-title">{{ $t('cooperationCase.t1') }}</div>
        <div class="left-box flex-col justify-center">
          <div class="case-content-wrapper row">
            <div class="logo-wrapper col-md-3 flex-col justify-center align-center">
              <img
                class="logo-img"
                referrerpolicy="no-referrer"
                src="../assets/cooperationCase/img/哈根达斯logo@2x.png"
              />
            </div>
            <div class="logo-content col-md-9 flex-row">
              <div class="flex-col justify-between">
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t1t1') }}</div>
                  <div class="logo-content-text col-lg-9">{{ $t('cooperationCase.t1p1') }}</div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t1t2') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t1p2') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t1t3') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t1p3') }}
                    <div><strong>{{ $t('cooperationCase.t1p4') }}</strong></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            class="case-img"
            referrerpolicy="no-referrer"
            src="../assets/cooperationCase/img/哈根达斯图片@2x.png"
          />
        </div>
        </div>
        <div id="section2" href="#section2">
        <div class="case-title">{{ $t('cooperationCase.t2') }}</div>
        <div class="left-box flex-col justify-center">
          <div class="case-content-wrapper row">
            <div class="logo-wrapper col-md-3 flex-col justify-center align-center">
              <img
                class="logo-img"
                referrerpolicy="no-referrer"
                src="../assets/cooperationCase/img/太原地铁logo@2x.png"
              />
            </div>
            <div class="logo-content col-md-9 flex-row">
              <div class="flex-col justify-between">
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t2t1') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t2p1') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t2t2') }}</div>
                  <div class="logo-content-text col-lg-9">{{ $t('cooperationCase.t2p2') }}</div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t2t3') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t2p3') }}
                    <div>
                      <strong>{{ $t('cooperationCase.t2p4') }}</strong>
                    </div>
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t2t4') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t2p5') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            class="case-img"
            referrerpolicy="no-referrer"
            src="../assets/cooperationCase/img/太原地铁图片@2x.png"
          />
        </div>
        </div>
        <div id="section3" href="#section3">
        <div class="case-title">{{ $t('cooperationCase.t3') }}</div>
        <div class="left-box flex-col justify-center">
          <div class="case-content-wrapper row">
            <div class="logo-wrapper col-md-3 flex-col justify-center align-center">
              <img
                class="logo-img"
                referrerpolicy="no-referrer"
                src="../assets/cooperationCase/img/鄂尔多斯机场logo@2x.png"
              />
            </div>
            <div class="logo-content col-md-9 flex-row">
              <div class="flex-col justify-between">
                <div class="row logo-content-item">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t3t1') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t3p1') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t3t2') }}</div>
                  <div class="logo-content-text col-lg-9">{{ $t('cooperationCase.t3p2') }}</div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t3t3') }}</div>
                  <div class="logo-content-text col-lg-9">
                    <strong> {{ $t('cooperationCase.t3p3') }}</strong>
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t3t4') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t3p4') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t3t5') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t3p5') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t3t6') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t3p6') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t3t7') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t3p7') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            class="case-img"
            referrerpolicy="no-referrer"
            src="../assets/cooperationCase/img/鄂尔多斯机场图片@2x.png"
          />
        </div>
        </div>
        <div id="section4" href="#section4">
        <div class="case-title">{{ $t('cooperationCase.t4') }}</div>
        <div class="left-box flex-col justify-center">
          <div class="case-content-wrapper row">
            <div class="logo-wrapper col-md-3 flex-col justify-center align-center">
              <img
                class="logo-img"
                referrerpolicy="no-referrer"
                src="../assets/cooperationCase/img/华尔道夫@2x.png"
              />
            </div>
            <div class="logo-content col-md-9 flex-row">
              <div class="flex-col justify-between">
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t4t1') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t4p1') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t4t2') }}</div>
                  <div class="logo-content-text col-lg-9"> {{ $t('cooperationCase.t4p2') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t4t3') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t4p3') }}
                    <div><strong>{{ $t('cooperationCase.t4p4') }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            class="case-img"
            referrerpolicy="no-referrer"
            src="../assets/cooperationCase/img/华尔道夫图片@2x.png"
          />
        </div>
        </div>

        <div class="case-title">{{ $t('cooperationCase.t5') }}</div>
        <div class="left-box flex-col justify-center">
          <div class="case-content-wrapper row">
            <div class="logo-wrapper col-md-3 flex-col justify-center align-center">
              <img
                class="logo-img"
                referrerpolicy="no-referrer"
                src="../assets/cooperationCase/img/眉州东坡logo@2x.png"
              />
            </div>
            <div class="logo-content col-md-9 flex-row">
              <div class="flex-col justify-between">
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t5t1') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t5p1') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3"> {{ $t('cooperationCase.t5t2') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t5p2') }}
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t5t3') }}</div>
                  <div class="logo-content-text col-lg-9">
                    <strong> {{ $t('cooperationCase.t5p3') }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            class="case-img"
            referrerpolicy="no-referrer"
            src="../assets/cooperationCase/img/眉州东坡图片@2x.png"
          />
        </div>

        <div class="case-title">{{ $t('cooperationCase.t6') }}</div>
        <div class="left-box flex-col justify-center">
          <div class="case-content-wrapper row">
            <div class="logo-wrapper col-md-3 flex-col justify-center align-center">
              <img
                class="logo-img"
                referrerpolicy="no-referrer"
                src="../assets/cooperationCase/img/箭牌卫浴logo@2x.png"
              />
            </div>
            <div class="logo-content col-md-9 flex-row">
              <div class="flex-col justify-between">
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t6t1') }}</div>
                  <div class="logo-content-text col-lg-9">{{ $t('cooperationCase.t6p1') }}</div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t6t2') }}</div>
                  <div class="logo-content-text col-lg-9">{{ $t('cooperationCase.t6p2') }}</div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t6t3') }}</div>
                  <div class="logo-content-text col-lg-9">
                    <strong>{{ $t('cooperationCase.t6p3') }}</strong>
                  </div>
                </div>
                <div class="logo-content-item row">
                  <div class="logo-content-title col-lg-3">{{ $t('cooperationCase.t6t4') }}</div>
                  <div class="logo-content-text col-lg-9">
                    {{ $t('cooperationCase.t6p4') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            class="case-img"
            referrerpolicy="no-referrer"
            src="../assets/cooperationCase/img/箭牌卫浴图片@2x.png"
          />
        </div>

        <div class="case-title">{{ $t('cooperationCase.t7') }}</div>
        <div class="left-box flex-col justify-center">
          <div class="case-content-wrapper row">
            <div class="col-lg-3 gu-content-box flex-col">
              <div class="gu-title1 flex-row">
                <div class="gu-title-text">{{ $t('cooperationCase.t7t1') }}</div>
              </div>
              <div class="gu-content-text">
                {{ $t('cooperationCase.t7p1') }}
              </div>
            </div>
            <div class="col-lg-3 gu-content-box">
              <div class="gu-title2 flex-row">
                <div class="gu-title-text">  {{ $t('cooperationCase.t7t2') }}</div>
              </div>
              <div class="gu-content-text">
                {{ $t('cooperationCase.t7p2') }}
              </div>
            </div>
            <div class="col-lg-3 gu-content-box">
              <div class="gu-title3 flex-row">
                <div class="gu-title-text">  {{ $t('cooperationCase.t7t3') }}</div>
              </div>
              <div class="gu-content-text">
                {{ $t('cooperationCase.t7p3') }}
              </div>
            </div>
            <div class="col-lg-3 gu-content-box">
              <div class="gu-title4 flex-row">
                <div class="gu-title-text">{{ $t('cooperationCase.t7t4') }}</div>
              </div>
              <div class="gu-content-text">{{ $t('cooperationCase.t7p4') }}
              </div>
            </div>
          </div>
          <img
            class="case-img"
            referrerpolicy="no-referrer"
            src="../assets/cooperationCase/img/古北水镇景区图片@2x.png"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CooperationPlan",
  data() {
    return {
      selectContentNumber: 1
    }
  },

  mounted() {
    const _id = this.$route.params.id
    if (_id) {
      const element = document.getElementById(_id);
      if (element) {
        element.scrollIntoView(true,{behavior: 'smooth', block: 'start'});
      }
    }
  },
  methods: {
    selectContent(number) {
      this.selectContentNumber = number;
    }
  }
}
</script>

<style scoped lang="css" src="../assets/common/common.css"/>
<style scoped lang="css" src="../assets/cooperationCase/index.css"/>
