import { render, staticRenderFns } from "./ProductCenter.vue?vue&type=template&id=3625a6ab&scoped=true&"
import script from "./ProductCenter.vue?vue&type=script&lang=js&"
export * from "./ProductCenter.vue?vue&type=script&lang=js&"
import style0 from "../assets/common/common.css?vue&type=style&index=0&id=3625a6ab&prod&scoped=true&lang=css&"
import style1 from "../assets/productCenter/index.css?vue&type=style&index=1&id=3625a6ab&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3625a6ab",
  null
  
)

export default component.exports